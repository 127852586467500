<template>
  <div style="padding: 20px">
    <Breadcrumb class="custom-breadcrumb" ref="breadcrumb">
      <BreadcrumbItem>员工互评管理</BreadcrumbItem>
      <BreadcrumbItem>被评价者</BreadcrumbItem>
    </Breadcrumb>
    <div style="display: flex;margin-top: 10px">
      <div class="right">
        <div style="height: 56px;margin-bottom: 10px;display: flex;background: white;align-items: center;padding: 0 24px">
          <Button
            @click="
              isEdit = false
              isNext = false
              addModal = true
            "
            icon="ios-add-circle-outline"
            type="primary"
            >新增被评价用户</Button
          >
          <Button @click="multiDel" type="primary" style="margin: 0 16px" icon="ios-trash-outline" :disabled="selectIds.length === 0">删除</Button>
          <Button
            @click="
              isEdit = true
              isNext = true
              addModal = true
            "
            type="primary"
            icon="ios-create-outline"
            :disabled="selectIds.length === 0"
            >修改试卷</Button
          >
        </div>
        <Table @on-selection-change="selectUser" :columns="columns" :data="tableData" stripe :height="tableH" :loading="loading">
          <template slot-scope="{ row, index }" slot="name">
            <div style="display: flex;align-items: center">
              <Avatar
                :src="row.avatar ? row.avatar : require('../../../assets/defaultAvatar.svg')"
                style="min-width: 30px;height: 30px;margin-right: 6px"
              ></Avatar>
              <div>{{ row.realName }}</div>
            </div>
          </template>
          <template slot-scope="{ row, index }" slot="date">
            <span>{{ $formatTime(row.createdTime, 'yyyy-MM-dd hh:mm') }}</span>
          </template>
          <template slot-scope="{ row, index }" slot="status">
            <span :style="{ color: row.score ? 'green' : 'red' }">{{ row.score ? '是' : '否' }}</span>
          </template>
          <template slot-scope="{ row }" slot="op">
            <Icon @click="singleEdit(row.appraiseId)" type="ios-create-outline" style="font-size: 20px;cursor: pointer" />
            <Icon @click="singleDel(row.appraiseId)" type="ios-trash-outline" style="font-size: 20px;margin-left: 20px;cursor: pointer" />
          </template>
        </Table>
        <Page
          @on-change="changePage"
          @on-page-size-change="changeSize"
          :total="total"
          show-sizer
          show-elevator
          show-total
          style="text-align: right;margin: 16px 20px"
        />
      </div>
    </div>
    <Modal v-model="addModal" @on-cancel="cancel" :width="isNext ? 1280 : 485">
      <div slot="header" style="font-size: 20px;color: #3A4E64;text-align: center">
        {{ isNext ? '选择评价问卷' : '添加被评价用户' }}
      </div>
      <div>
        <div style="text-align: center;color: #5E6470;font-size: 12px">
          {{ isNext ? '从以下选择评价试卷' : '从该部门选择被评价用户' }}
        </div>
        <div v-if="!isNext" class="user">
          <CheckboxGroup v-model="userIds">
            <Checkbox
              :style="{
                background: userIds.indexOf(item.userId) > -1 ? '#EBF0F6' : 'white'
              }"
              :label="item.userId"
              v-for="(item, index) in userData"
              :key="index"
              class="list"
            >
              <Avatar
                :src="item.avatar ? item.avatar : require('../../../assets/defaultAvatar.svg')"
                :size="22"
                style="margin: 0 8px 0 16px"
              ></Avatar>
              <span style="font-size: 14px">{{ item.realName }}</span>
            </Checkbox>
          </CheckboxGroup>
        </div>
        <div v-else class="selectTable">
          <div class="top">
            <div>
              <Select v-model="paperId" style="width:200px" @on-change="selectBank">
                <Option v-for="item in testPapers" :value="item.paperBankId" :key="item.paperBankId">{{ item.name }}</Option>
              </Select>
              <Radio disabled :value="selectPaperId !== ''" style="margin-left: 35px">已选择</Radio>
            </div>
            <Input search placeholder="请输入查找信息" class="search" v-model="keyword" @on-search="search" />
          </div>
          <Table
            highlight-row
            @on-current-change="selectPaper"
            :height="tableH - 90"
            :data="paperData"
            :columns="paperColumns"
            :loading="paperLoading"
            stripe
          >
            <template slot-scope="{ row }" slot="radio">
              <Radio :value="row.paperId === selectPaperId"></Radio>
            </template>
            <template slot-scope="{ row }" slot="level">
              <span>{{ checkLevel(row.level) }}</span>
            </template>
            <template slot-scope="{ row, index }" slot="cTime">
              <span>{{ $formatTime(row.createTime, 'yyyy-MM-dd hh:mm') }}</span>
            </template>
            <template slot-scope="{ row, index }" slot="uTime">
              <span>{{ $formatTime(row.updateTime, 'yyyy-MM-dd hh:mm') }}</span>
            </template>
          </Table>
        </div>
      </div>
      <div slot="footer" style="text-align: center">
        <Button style="width: 90px" @click="cancel">取消</Button>
        <Button type="primary" style="width: 90px;margin-left: 10px" @click="next" :loading="confirmLoading">{{ isNext ? '确定' : '下一步' }}</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import department from '@api/department'
import exam from '@api/exam'
import { difficulty } from '@util/difficulty'
import paper from '@api/paper'

export default {
  name: 'AppraisedPerson',
  data() {
    return {
      selectAppraiseIds: [],
      isEdit: false,
      selectPaperId: '',
      appraiserId: '',
      confirmLoading: false,
      paperId: '',
      testPapers: [],
      userIds: [],
      paperLoading: false,
      paperData: [],
      loading: false,
      selectIds: [],
      addModal: false,
      isNext: false,
      tableH: document.body.clientHeight - 204,
      total: 10,
      userData: [],
      treeData: [],
      paperColumns: [
        {
          title: '#',
          slot: 'radio',
          width: 60,
          align: 'center'
        },
        {
          title: '问卷名',
          key: 'name'
        },
        {
          title: '总分',
          key: 'totalPoints'
        },
        {
          title: '题量',
          key: 'questionAmount'
        },
        {
          title: '难度',
          slot: 'level'
        },
        {
          title: '创建时间',
          slot: 'cTime'
        },
        {
          title: '修改时间',
          slot: 'uTime'
        },
        {
          title: '创建人',
          key: 'creatorName'
        }
      ],
      columns: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: '用户名',
          slot: 'name'
        },
        {
          title: '岗位',
          key: 'postName'
        },
        {
          title: '评价卷名称',
          key: 'paperName'
        },
        {
          title: '评价分数',
          key: 'score'
        },
        {
          title: '日期',
          slot: 'date'
        },
        {
          title: '评价状态',
          slot: 'status'
        },
        {
          title: '操作',
          slot: 'op'
        }
      ],
      tableData: [],
      page: 0,
      size: 10,
      userId: this.$route.query.id,
      keyword: '',
      initPaperBankId: '',
      initPaperData: []
    }
  },
  created() {
    this.getUserAppraise()
    paper.getAllPaperBanks().then(res => {
      this.testPapers = res.res
      if (res.res.length > 0) {
        this.initPaperBankId = res.res[0].paperBankId
        this.paperId = res.res[0].paperBankId
        this.getPapers(res.res[0].paperBankId, true)
      }
    })
    exam.getRelated(this.$route.query.dId).then(res => {
      this.userData = res.res
    })
  },
  methods: {
    selectPaper(data) {
      this.selectPaperId = data.paperId
    },
    search() {
      if (this.paperId === '') {
        this.$message.warning('请选择问卷库')
        return
      }
      this.paperLoading = true
      paper
        .searchPaper(0, 10000, {
          paperBankId: this.paperId,
          name: this.keyword
        })
        .then(res => {
          this.paperData = res.res.data
          this.paperLoading = false
        })
        .catch(() => {
          this.paperLoading = false
        })
    },
    selectBank(val) {
      this.paperId = val
      this.keyword = ''
      this.getPapers(val)
    },
    getPapers(id, init) {
      this.paperLoading = true
      paper
        .getAllPaperList(id)
        .then(res => {
          this.paperData = res.res.data
          this.paperLoading = false
          if (init) {
            this.initPaperData = res.res.data
          }
        })
        .catch(() => {
          this.paperLoading = false
        })
    },
    next() {
      if (!this.isEdit) {
        if (this.userIds.length === 0) {
          this.$message.error('请选择被评价者')
          return
        }
        if (!this.isNext) {
          this.isNext = true
        } else {
          if (this.selectPaperId === '') {
            this.$message.error('请选择问卷')
            return
          } else {
            const payload = {
              appraiserId: this.userId,
              paperId: this.selectPaperId,
              toUserIds: this.userIds
            }
            this.confirmLoading = true
            exam
              .addAppraise(payload)
              .then(res => {
                this.$message.success('添加成功')
                this.getUserAppraise()
                this.cancel()
              })
              .catch(() => {
                this.confirmLoading = false
              })
          }
        }
      } else {
        if (this.selectPaperId === '') {
          this.$message.error('请选择问卷')
          return
        } else {
          exam.editAppraise(this.selectPaperId, this.selectAppraiseIds).then(res => {
            if (res.res) {
              this.$message.success('修改成功')
              this.getUserAppraise()
              this.cancel()
            }
          })
        }
      }
    },
    singleEdit(id) {
      this.isEdit = true
      this.isNext = true
      this.addModal = true
      this.selectAppraiseIds = [id]
    },
    cancel() {
      this.addModal = false
      this.userIds = []
      this.keyword = ''
      this.selectPaperId = ''
      this.paperData = this.initPaperData
      this.paperId = this.initPaperBankId
      this.confirmLoading = false
      setTimeout(() => {
        this.isNext = false
        this.paperId = ''
      }, 500)
    },
    checkLevel(num) {
      return difficulty[num].label
    },
    selectUser(data) {
      this.selectIds = data.map(item => {
        return item.userId
      })
      this.selectAppraiseIds = data.map(item => {
        return item.appraiseId
      })
    },
    multiDel() {
      this.$Modal.confirm({
        title: '提示',
        content: '<p>确认删除选中的被评价者吗？</p>',
        loading: true,
        onOk: () => {
          const payload = {
            fromUserId: this.userId,
            toUserIds: this.selectIds
          }
          exam.delByUserId(payload).then(res => {
            this.$message.success('删除成功')
            this.selectIds = []
            this.$Modal.remove()
            this.getUserAppraise()
          })
        }
      })
    },
    singleDel(id) {
      this.$Modal.confirm({
        title: '提示',
        content: '<p>确认删除该被评价者吗？</p>',
        loading: true,
        onOk: () => {
          // const payload = {
          //   fromUserId: this.userId,
          //   toUserIds: [id]
          // }
          const payload = [id]
          exam.delByUserId(payload).then(res => {
            this.$message.success('删除成功')
            this.selectIds = []
            this.$Modal.remove()
            this.getUserAppraise()
          })
        }
      })
    },
    getUserAppraise() {
      this.loading = true
      exam
        .getUserAppraise(this.userId, this.page, this.size)
        .then(res => {
          this.total = res.res.total
          this.tableData = res.res.data
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    changePage(val) {
      this.page = val - 1
      this.getUserAppraise()
    },
    changeSize(val) {
      this.size = val
      this.getUserAppraise()
    }
  }
}
</script>

<style scoped lang="less">
.left {
  min-width: 300px;
  overflow: auto;
  box-shadow: 0px 0px 5px rgba(7, 27, 41, 0.1);
  .top {
    height: 56px;
    background: #e4e9ef;
    font-size: 16px;
    color: #3a4e64;
    font-weight: 400;
    padding: 16px 23px;
    display: flex;
  }
  .tree {
    padding: 15px 12px;
    text-align: left;
  }
}
.right {
  box-shadow: 0px 0px 5px rgba(7, 27, 41, 0.1);
  flex: auto;
}
.user {
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(228, 233, 239, 1);
  opacity: 1;
  border-radius: 4px;
  overflow: auto;
  margin-top: 16px;
  padding-left: 8px;
  max-height: calc(100vh - 325px);
  .list {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border-radius: 4px;
    margin: 6px;
    &:hover {
      background: #ebf0f6;
    }
  }
}
.selectTable {
  box-shadow: 0px 0px 5px rgba(7, 27, 41, 0.1);
  margin-top: 16px;
  .top {
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    .search {
      width: 300px;
      ::v-deep .ivu-input {
        border: 0;
        border-bottom: 1px solid #ccc;
        border-radius: 0;

        &:focus {
          box-shadow: 0 1px 0 rgba(45, 140, 240, 0.2);
        }
      }
    }
  }
}
::v-deep .ivu-tree ul {
  font-size: 14px;
  color: #5e6470;
}
::v-deep .ivu-modal-body {
  padding: 0 50px 20px 50px;
}
::v-deep .ivu-modal-header {
  padding: 30px 16px 10px 16px;
}
</style>
