var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding":"20px"}},[_c('Breadcrumb',{ref:"breadcrumb",staticClass:"custom-breadcrumb"},[_c('BreadcrumbItem',[_vm._v("员工互评管理")]),_c('BreadcrumbItem',[_vm._v("被评价者")])],1),_c('div',{staticStyle:{"display":"flex","margin-top":"10px"}},[_c('div',{staticClass:"right"},[_c('div',{staticStyle:{"height":"56px","margin-bottom":"10px","display":"flex","background":"white","align-items":"center","padding":"0 24px"}},[_c('Button',{attrs:{"icon":"ios-add-circle-outline","type":"primary"},on:{"click":function($event){_vm.isEdit = false
            _vm.isNext = false
            _vm.addModal = true}}},[_vm._v("新增被评价用户")]),_c('Button',{staticStyle:{"margin":"0 16px"},attrs:{"type":"primary","icon":"ios-trash-outline","disabled":_vm.selectIds.length === 0},on:{"click":_vm.multiDel}},[_vm._v("删除")]),_c('Button',{attrs:{"type":"primary","icon":"ios-create-outline","disabled":_vm.selectIds.length === 0},on:{"click":function($event){_vm.isEdit = true
            _vm.isNext = true
            _vm.addModal = true}}},[_vm._v("修改试卷")])],1),_c('Table',{attrs:{"columns":_vm.columns,"data":_vm.tableData,"stripe":"","height":_vm.tableH,"loading":_vm.loading},on:{"on-selection-change":_vm.selectUser},scopedSlots:_vm._u([{key:"name",fn:function(ref){
            var row = ref.row;
            var index = ref.index;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('Avatar',{staticStyle:{"min-width":"30px","height":"30px","margin-right":"6px"},attrs:{"src":row.avatar ? row.avatar : require('../../../assets/defaultAvatar.svg')}}),_c('div',[_vm._v(_vm._s(row.realName))])],1)]}},{key:"date",fn:function(ref){
            var row = ref.row;
            var index = ref.index;
return [_c('span',[_vm._v(_vm._s(_vm.$formatTime(row.createdTime, 'yyyy-MM-dd hh:mm')))])]}},{key:"status",fn:function(ref){
            var row = ref.row;
            var index = ref.index;
return [_c('span',{style:({ color: row.score ? 'green' : 'red' })},[_vm._v(_vm._s(row.score ? '是' : '否'))])]}},{key:"op",fn:function(ref){
            var row = ref.row;
return [_c('Icon',{staticStyle:{"font-size":"20px","cursor":"pointer"},attrs:{"type":"ios-create-outline"},on:{"click":function($event){return _vm.singleEdit(row.appraiseId)}}}),_c('Icon',{staticStyle:{"font-size":"20px","margin-left":"20px","cursor":"pointer"},attrs:{"type":"ios-trash-outline"},on:{"click":function($event){return _vm.singleDel(row.appraiseId)}}})]}}])}),_c('Page',{staticStyle:{"text-align":"right","margin":"16px 20px"},attrs:{"total":_vm.total,"show-sizer":"","show-elevator":"","show-total":""},on:{"on-change":_vm.changePage,"on-page-size-change":_vm.changeSize}})],1)]),_c('Modal',{attrs:{"width":_vm.isNext ? 1280 : 485},on:{"on-cancel":_vm.cancel},model:{value:(_vm.addModal),callback:function ($$v) {_vm.addModal=$$v},expression:"addModal"}},[_c('div',{staticStyle:{"font-size":"20px","color":"#3A4E64","text-align":"center"},attrs:{"slot":"header"},slot:"header"},[_vm._v(" "+_vm._s(_vm.isNext ? '选择评价问卷' : '添加被评价用户')+" ")]),_c('div',[_c('div',{staticStyle:{"text-align":"center","color":"#5E6470","font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.isNext ? '从以下选择评价试卷' : '从该部门选择被评价用户')+" ")]),(!_vm.isNext)?_c('div',{staticClass:"user"},[_c('CheckboxGroup',{model:{value:(_vm.userIds),callback:function ($$v) {_vm.userIds=$$v},expression:"userIds"}},_vm._l((_vm.userData),function(item,index){return _c('Checkbox',{key:index,staticClass:"list",style:({
              background: _vm.userIds.indexOf(item.userId) > -1 ? '#EBF0F6' : 'white'
            }),attrs:{"label":item.userId}},[_c('Avatar',{staticStyle:{"margin":"0 8px 0 16px"},attrs:{"src":item.avatar ? item.avatar : require('../../../assets/defaultAvatar.svg'),"size":22}}),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(item.realName))])],1)}),1)],1):_c('div',{staticClass:"selectTable"},[_c('div',{staticClass:"top"},[_c('div',[_c('Select',{staticStyle:{"width":"200px"},on:{"on-change":_vm.selectBank},model:{value:(_vm.paperId),callback:function ($$v) {_vm.paperId=$$v},expression:"paperId"}},_vm._l((_vm.testPapers),function(item){return _c('Option',{key:item.paperBankId,attrs:{"value":item.paperBankId}},[_vm._v(_vm._s(item.name))])}),1),_c('Radio',{staticStyle:{"margin-left":"35px"},attrs:{"disabled":"","value":_vm.selectPaperId !== ''}},[_vm._v("已选择")])],1),_c('Input',{staticClass:"search",attrs:{"search":"","placeholder":"请输入查找信息"},on:{"on-search":_vm.search},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1),_c('Table',{attrs:{"highlight-row":"","height":_vm.tableH - 90,"data":_vm.paperData,"columns":_vm.paperColumns,"loading":_vm.paperLoading,"stripe":""},on:{"on-current-change":_vm.selectPaper},scopedSlots:_vm._u([{key:"radio",fn:function(ref){
            var row = ref.row;
return [_c('Radio',{attrs:{"value":row.paperId === _vm.selectPaperId}})]}},{key:"level",fn:function(ref){
            var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.checkLevel(row.level)))])]}},{key:"cTime",fn:function(ref){
            var row = ref.row;
            var index = ref.index;
return [_c('span',[_vm._v(_vm._s(_vm.$formatTime(row.createTime, 'yyyy-MM-dd hh:mm')))])]}},{key:"uTime",fn:function(ref){
            var row = ref.row;
            var index = ref.index;
return [_c('span',[_vm._v(_vm._s(_vm.$formatTime(row.updateTime, 'yyyy-MM-dd hh:mm')))])]}}])})],1)]),_c('div',{staticStyle:{"text-align":"center"},attrs:{"slot":"footer"},slot:"footer"},[_c('Button',{staticStyle:{"width":"90px"},on:{"click":_vm.cancel}},[_vm._v("取消")]),_c('Button',{staticStyle:{"width":"90px","margin-left":"10px"},attrs:{"type":"primary","loading":_vm.confirmLoading},on:{"click":_vm.next}},[_vm._v(_vm._s(_vm.isNext ? '确定' : '下一步'))])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }